import React from 'react';
import blankAvatar from './blank-avatar.png'
import { connect } from 'react-redux';

const Users = ({ avatars }) => {
    // generate user placeholders
    const userPlaceholders = [];
    function generateUsersPlaceholders(maxUsers) {
        if(avatars.length < maxUsers) {
            let i = avatars.length;
            for(i; i < maxUsers; i++){
                userPlaceholders.push(
                    <div key={i} className={`user user-${i + 1} opacity-4`}>
                        <img className="round" src={blankAvatar} alt={`user-${i + 1}`} />
                    </div>
                )
            }
        }
    }
    generateUsersPlaceholders(36);

    return (
        <div className="users">
            {avatars.map((avatar, index) => (
                <div key={index} className={`user user-${index + 1}`}>
                    <img className="round" src={avatar.avatar} alt={`user-${index + 1}`} />
                </div>
            ))}
            {userPlaceholders}
        </div>
    )
}

const mapStateToProps = state => ({
    avatars: state.profile.profiles.avatars
})

export default connect(mapStateToProps, null)(Users);