import { PROFILE_FAIL, PROFILE_SUCCESS, CLEAR_PROFILE, PROFILES_COUNT_SUCCESS, PROFILES_COUNT_FAIL, GET_AVATARS, GET_AVATARS_FAIL } from '../actions/types';

const initialState = {
    profile: null,
    profiles: {
        data: null,
        total: null,
        avatars: null
    },
    errors: {},
    loading: true
}

export default function(state = initialState, action) {

    const { payload, type } = action;

    switch(type) {
        case GET_AVATARS:
            return {
                ...state,
                profiles: { ...state.profiles, avatars: payload }
            }
        case PROFILE_SUCCESS:
            return {
                ...state,
                profile: payload,
                loading: false
            }
        case PROFILES_COUNT_SUCCESS:
            return {
                ...state,
                profiles: { ...state.profiles, total: payload }
            }
        
        case PROFILES_COUNT_FAIL:
            return {
                ...state,
                profiles: { data: null, total: null, avatars: null }
            }
        case GET_AVATARS_FAIL:
            return {
                ...state,
                profiles: { ...state, avatars: initialState.profiles.avatars }
            }
        case CLEAR_PROFILE:
        case PROFILE_FAIL:
            return {
                ...state,
                profile: null,
                profiles: { ...state.profiles, data: null },
                loading: false,
                errors: { ...state.errors, ...payload }
            }
        default:
            return state;
    }
    
}