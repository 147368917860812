import React, { Fragment, useEffect } from 'react';
import twitter from '../landing/twitter-icon.png'
import facebook from '../landing/facebook-icon.png'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProfilesCount } from '../../actions/profile';

const ThankYou = ({ getProfilesCount, profiles: { total }, config }) => {

    useEffect(() => {
        getProfilesCount();
    }, [getProfilesCount])

    return (
        <Fragment>
            <div id="content" className="container content my-4">
                <div className="my-5 content-inner text-center">
                    <h1 className="col-md-12 title h1"><span role="img" aria-label="check">✅</span> Thank you!</h1>
                    <p className="lead mb-5">You've successfuly created a profile on BuddyList.</p>
                    <div className="col-md-12 share text-center my-1">
                        <div className="my-3">
                            <h2>The project will be launched at: <br /><span>{ total ? total : '?' } / 5 000 users</span></h2>
                            <p className="lead">We want to reach certain amount of users before we launch the project, that way we can make sure everybody get the best experience possible.</p>
                        </div>
                        { config && <p className="lead">
                            We'd be happy <span role="img" aria-label="happy-kitty">😺</span> if you help us to launch the project faster, <br/>please <span role="img" aria-label="pray">🙏</span> consider: <a href={config.shareLinks.twitter} target="_new" rel="noopener noreferrer" className="btn share-icon mx-1"><img src={twitter} alt="tweet" /></a><a href={config.shareLinks.facebook} target="_new" rel="noopener noreferrer" className="btn share-icon mx-1"><img src={facebook} alt="facebook-share" /></a>
                        </p> }
                        <p className="lead py-3">
                            We'll notify everyone via. Email <span role="img" aria-label="envelope">✉️</span>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ThankYou.propTypes = {
    profiles: PropTypes.object.isRequired,
    getProfilesCount: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    profiles: state.profile.profiles,
    config: state.config
})

export default connect(mapStateToProps, { getProfilesCount })(ThankYou);