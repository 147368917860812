import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { FixedSizeList as List } from "react-window";
import { editFormData } from '../../actions/formData';

const SelectCity = ({ location:{ cities }, formData: { data: { city }, settings: { citySearched } }, editFormData }) => {

    const handleChange = (e) => {
        if(e.target.name === 'citySearched') {
            // reset formData.city if user editing city' search input field
            editFormData({ city: '' }, null);
            editFormData(null, { citySearched: e.target.value });
        } else {
            // other formData form fields
            editFormData({ [e.target.name]: e.target.value }, null);
        }
    }

    // filter cities by city search field
    let filteredCities = cities.filter(city => {
        return city.name.toLowerCase().indexOf(citySearched.toLowerCase().trim()) !== -1;
    })

    const handleChooseCity = (id, name) => {
        //set city in main form (formData)
        editFormData({ city: id, }, { citySearched: name });
    }

    const handleCityBlur = () => {
        // reset search field if user exit from the city search field without choosing city
        if(!city) {
            editFormData(null, { citySearched: '' });
        }
    }

    // dynamic list rendering for cities list (react-window library)
    const Item = ({ index, style }) => (
    <li className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style} onMouseDown={() => handleChooseCity(filteredCities[index].id, filteredCities[index].name)}>
        {filteredCities[index].name}
    </li>
    );    

    return (
        <Fragment>
            {
                cities ? (
                    <div className="form-group">
                        <label className="my-1 mr-2" htmlFor="city"><span role="img" aria-label="night-city">🌃</span> City</label>
                        <input className="form-control" placeholder="Enter city name to search..." type="text" onBlur={handleCityBlur} onChange={(e) => handleChange(e)} name="citySearched" value={citySearched} />
                        <div className="city-list-container">
                            <ul className={citySearched.length > 0 && !city ? 'cities-list form-control d-block' : 'cities-list form-control d-none'}>
                                { 
                                    filteredCities && filteredCities.length > 0 ? <List className="List" height={100} itemCount={filteredCities.length} itemSize={35}>{Item}</List> : <span>Nothing found.</span>
                                }
                            </ul>
                        </div>
                        <small id="cityName" className="form-text text-muted">Select your city</small>
                    </div>
                ) : (<Spinner />)
            }
        </Fragment>
    )
}

SelectCity.propTypes = {
    location: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    editFormData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    location: state.location,
    formData: state.formData
})

export default connect(mapStateToProps, { editFormData })(SelectCity);