import { GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL, GET_STATES_SUCCESS, GET_STATES_FAIL, GET_CITIES_SUCCESS, GET_CITIES_FAIL, CLEAR_LOCATION_STATES, CLEAR_LOCATION_CITIES } from '../actions/types';

const initialState = {
    countries: null,
    cities: null,
    states: null,
    loading: true
}

export default function(state = initialState, action) {
    
    const { type, payload } = action;

    switch(type){
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: payload,
                loading: false
            }
        case GET_COUNTRIES_FAIL:
            return {
                ...state,
                countries: null,
                loading: false
            }
        case GET_STATES_SUCCESS:
            return {
                ...state,
                states: payload,
                loading: false
            }
        case GET_STATES_FAIL:
            return {
                ...state,
                states: null,
                loading: false
            }
        case GET_CITIES_SUCCESS:
            return {
                ...state,
                cities: payload,
                loading: false
            }
        case GET_CITIES_FAIL:
            return {
                ...state,
                cities: null,
                loading: false
            }
        case CLEAR_LOCATION_STATES:
            return {
                ...state,
                states: null,
                loading: false
            }
        case CLEAR_LOCATION_CITIES:
            return {
                ...state,
                cities: null,
                loading: false
            }
        default:
            return state;
    }
}