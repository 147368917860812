import { EDIT_FORM_DATA_SUCCESS, EDIT_FORM_DATA_FAIL, EDIT_FORM_DATA_SETTINGS_SUCCESS, EDIT_FORM_DATA_SETTINGS_FAIL, SETUP_CROP, SETUP_CROP_ERROR, UPLOAD_VALIDATION_SUCCESS, UPLOAD_VALIDATION_FAIL, UPLOAD_VALIDATION_ERROR, SETUP_FORM_DATA_SUCCESS, SETUP_FORM_DATA_FAIL, FORM_NEXT_STEP_SUCCESS, FORM_PREV_STEP_SUCCESS, FORM_STEP_FAIL, CLEAR_FORM_DATA } from '../actions/types';

const initialState = {
    data: null,
    settings: null,
    loading: true
}

export default function(state = initialState, action) {

    const { payload, type } = action;

    switch(type) {
        case SETUP_FORM_DATA_SUCCESS:
            return {
                ...state,
                data: payload.initialState,
                settings: payload.initialSettings,
                loading: false
            }
        case EDIT_FORM_DATA_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...payload }
            }
        case EDIT_FORM_DATA_FAIL:
            return {
                ...state,
                data: initialState.data
            }
        case EDIT_FORM_DATA_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: { ...state.settings, ...payload }
            }
        case EDIT_FORM_DATA_SETTINGS_FAIL:
            return {
                ...state,
                settings: initialState.settings
            }    
        case SETUP_CROP:
            return {
                ...state,
                settings: { ...state.settings, avatar: { ...state.settings.avatar, ...payload } }
            }
        case UPLOAD_VALIDATION_SUCCESS:
            return {
                ...state,
                settings: { ...state.settings, avatar: { ...state.settings.avatar, image: null, ...payload } }
            }
        case UPLOAD_VALIDATION_FAIL:
            return {
                ...state,
                settings: { ...state.settings, avatar: { ...state.settings.avatar, image: null, croppedAvatarUrl: null, croppedAvatar: null, avatarPreview: false, ...payload } }
            }
        case FORM_NEXT_STEP_SUCCESS:
            return {
                ...state,
                settings: { ...state.settings, step: state.settings.step + 1 }
            }
        case FORM_PREV_STEP_SUCCESS:
            return {
                ...state,
                settings: { ...state.settings, step: state.settings.step - 1 }
            }
        case FORM_STEP_FAIL:
            return {
                ...state,
                settings: { ...state.settings, step: initialState.settings.step }
            }
        case UPLOAD_VALIDATION_ERROR:
        case SETUP_CROP_ERROR:
            return {
                ...state,
                settings: { ...state.settings, avatar: initialState.settings.avatar }
            }
        case CLEAR_FORM_DATA:
        case SETUP_FORM_DATA_FAIL:
            return {
                ...initialState,
                loading: false
            }
        default:
            return state;
    }
    
}