import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import GA from './utils/GoogleAnalytics';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Landing from './components/landing/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfService from './components/pages/TermsOfService';
import HowItWorks from './components/pages/HowItWorks';
import ThankYou from './components/pages/ThankYou';
import CreateProfile from './components/profile/CreateProfile';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import { loadUser } from './actions/auth';
import userAuth from './utils/userAuth';

// Gooogle Tag Manger
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-52WPGX8'
}
TagManager.initialize(tagManagerArgs)

if(localStorage.token) {
  userAuth(localStorage.token)
}

function App() {

  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
          { GA.init() && <GA.RouteTracker /> }
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/how-it-works" component={HowItWorks} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <PrivateRoute path="/thank-you" component={ThankYou} />
            <PrivateRoute path="/create-profile" component={CreateProfile} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route component={NotFound} />
          </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
