import { EDIT_FORM_DATA_SUCCESS, EDIT_FORM_DATA_FAIL, EDIT_FORM_DATA_SETTINGS_SUCCESS, EDIT_FORM_DATA_SETTINGS_FAIL, UPLOAD_VALIDATION_FAIL, UPLOAD_VALIDATION_SUCCESS, UPLOAD_VALIDATION_ERROR, SETUP_CROP, SETUP_CROP_ERROR, SETUP_FORM_DATA_SUCCESS, SETUP_FORM_DATA_FAIL, FORM_NEXT_STEP_SUCCESS, FORM_PREV_STEP_SUCCESS, FORM_STEP_FAIL } from './types';

// get formData
export const setupFormData = (initialState, initialSettings) => async dispatch => {
    try {
        dispatch({
            type: SETUP_FORM_DATA_SUCCESS,
            payload: { initialState: initialState, initialSettings: initialSettings }
        })
    } catch (err) {
        dispatch({
            type: SETUP_FORM_DATA_FAIL
        })
    }
}

// edit formData redux state
export const editFormData = (data, settings) => async dispatch => {
    if(data) {
        try {
            dispatch({
                type: EDIT_FORM_DATA_SUCCESS,
                payload: data
            })
        } catch (err) {
            dispatch({
                type: EDIT_FORM_DATA_FAIL
            })
        } 
    }
    
    if(settings) {
        try {
            dispatch({
                type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
                payload: settings
            })
        } catch (err) {
            dispatch({
                type: EDIT_FORM_DATA_SETTINGS_FAIL
            })
        }
    }
}


// file upload has passed validation
export const uploadValidationSuccess = (data) => async dispatch => {
    try {
        dispatch({
            type: UPLOAD_VALIDATION_SUCCESS,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: UPLOAD_VALIDATION_ERROR,
        })
    }
}

// file upload hasnt passed validation
export const uploadValidationFail = (data) => async dispatch => {
    try {
        dispatch({
            type: UPLOAD_VALIDATION_FAIL,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: UPLOAD_VALIDATION_ERROR,
        })
    }
}

// settting settings for crop functionaly
export const setupCrop = (data) => async dispatch => {
    try {
        dispatch({
            type: SETUP_CROP,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: SETUP_CROP_ERROR
        })
    }
}

// formData next step
export const formNextStep = () => async dispatch => {
    try {
        dispatch({
            type: FORM_NEXT_STEP_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: FORM_STEP_FAIL
        })
    }
}

// formData previous step
export const formPrevStep = () => async dispatch => {
    try {
        dispatch({
            type: FORM_PREV_STEP_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: FORM_STEP_FAIL
        })
    }
}