const initialState = {
    shareLinks: {
        twitter: "https://twitter.com/intent/tweet?url=https%3A%2F%2Fbuddylist.me&text=Look%20what%20I've%20found%3A%20BuddyList%20%E2%80%94%20Tinder-like%20social%20media%20platform%20for%20professional%20connections!%20Check%20it%20out%3A",
        facebook: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuddylist.me"
    }
};

export default function (state = initialState, action) {

    const { type, payload } = action;
    
    switch(type){
        default:
            return state;
    }
}