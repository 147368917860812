import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ThankYou from '../pages/ThankYou';
import Spinner from '../layout/Spinner';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { getCurrentProfile } from '../../actions/profile';
import { loadUser } from '../../actions/auth';

const Dashboard = ({ profile: { profile, loading }, getCurrentProfile, loadUser }) => {

    useEffect(() => {
        loadUser();
        getCurrentProfile();
    }, [getCurrentProfile, loadUser])
    
    return (
        <Fragment>
            <div className="main-wrapper">
                <Header />
                    {
                        profile == null && !loading ? (<Fragment>
                            <div className="container text-center my-5">
                                <h2 className="h1 create-profile-notice">You don't have a profile yet.</h2>
                                <p className="lead">Please, create a profile so you can access the dashboard and interact with other users.</p>
                                <Link to="/create-profile" className="btn btn-success">Create</Link>
                            </div>
                        </Fragment>) : profile !== null && !loading ? (<ThankYou />) : (<Spinner />)
                    }
                </div>
            <Footer />
        </Fragment>
    )
}

Dashboard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    profile: state.profile
})

export default connect(mapStateToProps, { getCurrentProfile, loadUser })(Dashboard);

