import { REGISTER_SUCCESS, REGISTER_FAIL, LOADUSER_FAIL, LOADUSER_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, CLEAR_PROFILE, CLEAR_FORM_DATA } from './types';
import axios from 'axios';
import userAuth from '../utils/userAuth';
import { setAlert } from '../actions/alert';

export const loadUser = () => async dispatch => {

    if(localStorage.token) {
        userAuth(localStorage.token);
    }

    try {
        const res = await axios.get('/api/auth');

        dispatch({
            type: LOADUSER_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: LOADUSER_FAIL
        })
    }
}

// User Register
export const registerUser = (formData, setFormData, history) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(formData);

    try {

        const res = await axios.post('/api/users', body, config)

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        })
        
        history.push('/create-profile')
    } catch (err) {
        const errors = err.response.data.errors;
        
        if(errors) {
            errors.forEach(error => {
                dispatch(setAlert(error.msg, 'danger'))
            })
        }

        // set isLoading to false to remove loading Spinner
        setFormData({ ...formData, isLoading: false})

        dispatch({
            type: REGISTER_FAIL
        })
    }
}


// User Login
export const userLogin = (formData, setFormData, history) => async dispatch => {
    try {
        const config = {
            header: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/api/auth', formData, config)

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })

        history.push('/dashboard')
    } catch (err) {
        const errors = err.response.data.errors;
        
        if(errors) {
            errors.forEach(error => {
                dispatch(setAlert(error.msg, 'danger'))
            })
        }

        // set isLoading to false to remove loading Spinner
        setFormData({ ...formData, isLoading: false})
        
        dispatch({
            type: LOGIN_FAIL
        })
    }
}

export const userLogout = () => async dispatch => {
    try {
        dispatch({
            type: LOGOUT_SUCCESS
        })
        dispatch({
            type: CLEAR_PROFILE
        })
        dispatch({
            type: CLEAR_FORM_DATA
        })
    } catch (err) {
        dispatch(setAlert('Logout failed. Please, try again.', 'danger'))
    }
}