import axios from 'axios';
import { PROFILE_FAIL, PROFILE_SUCCESS, CLEAR_PROFILE, GET_PROFILES_SUCCESS, GET_PROFILES_FAIL, PROFILES_COUNT_SUCCESS, PROFILES_COUNT_FAIL, GET_AVATARS, GET_AVATARS_FAIL } from './types';

export const createProfile = (formData, avatarFormData, history) => async dispatch => {
    try {
        // form data config
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // avatar upload config
        const avatarConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // check if avatar was choosen by the user
        if(avatarFormData !== null) {
            // create new user profile
            await axios.post('/api/profile', formData, config);
            // upload user avatar by sending separate POST request
            const avatar = await axios.post('/api/uploads/avatar', avatarFormData, avatarConfig);

            dispatch({
                type: PROFILE_SUCCESS,
                payload: avatar.data
            })
        } else {
            // create new user profile
            await axios.post('/api/profile', formData, config);
        }

        history.push('/dashboard');
    } catch (err) {
        dispatch({
            type: PROFILE_FAIL,
            payload: { msg: err.response.StatusText, status: err.response.status }
        })
    }
}

export const getCurrentProfile = () => async dispatch => {
    try {
        const res = await axios.get('/api/profile/me');

        dispatch({
            type: CLEAR_PROFILE
        })

        dispatch({
            type: PROFILE_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILE_FAIL,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get all user profiles
export const getAllProfiles = () => async dispatch => {
    try {
        const profiles = await axios.get('/api/profile/all');

        dispatch({
            type: GET_PROFILES_SUCCESS,
            payload: profiles
        })
    } catch (err) {
        dispatch({
            type: GET_PROFILES_FAIL
        })
    }
}

// get totatl profiles count
export const getProfilesCount = () => async dispatch => {
    
    const res = await axios.get('/api/profile/profiles/total');

    try {
        dispatch({
            type: PROFILES_COUNT_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILES_COUNT_FAIL
        })
    }

}

// get random avatars
export const getAvatars = () => async dispatch => {
    const res = await axios.get('/api/profile/profiles/random_avatars');
    
    try {
        dispatch({
            type: GET_AVATARS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_AVATARS_FAIL
        })
    }
}