import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Alert = ({ alert }) => {
    return (
        <Fragment>
            <div className="alert-container col-lg-12 my-4">
                { alert !== null && alert.length > 0 && (
                    alert.map(item => 
                        <div className={`alert alert-${item.alertType}`} key={item.id} role="alert">
                            {item.msg}
                        </div>
                    )
                ) }
            </div>
        </Fragment>
    )
}

Alert.propTypes = {
    alert: PropTypes.array
}

const mapStateToProps = state => ({
    alert: state.alert
})

export default connect(mapStateToProps)(Alert);