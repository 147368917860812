import React, { Fragment, useState } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Header from '../layout/Header';
import Alert from '../layout/Alert';
import PrivacyNotice from '../layout/PrivacyNotice';
import Spinner from '../layout/Spinner';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { registerUser } from '../../actions/auth';
import PropTypes from 'prop-types';
// phone input
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Register = ({ alert, auth: { isAuthenticated, loading }, setAlert, registerUser, history }) => {

    const initialState = {
        email: null,
        password: null,
        password2: null,
        phone: null,
        isLoading: false
    }

    const [ formData, setFormData ] = useState(initialState)

    const  { email, password, password2, phone, isLoading } = formData;

    const handleChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }
    
    const handlePhone = (phone) => {
        setFormData({ ...formData, phone: phone })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!email || !password || !password2 || !phone) {
            return setAlert('Please, fill out all required fields.', 'danger');
        }

        setFormData({ ...formData, isLoading: true })

        if(password !== password2) {
            setFormData({ ...formData, password: '', password2: '', isLoading: false });
            setAlert('Passwords do not match. Please, try again.', 'danger');
        } else {
            registerUser(formData, setFormData, history);
        }
    }

    return (
        <Fragment>
        <Header />
            {
                loading ? (<Spinner />) : isAuthenticated ? (<Redirect to="/dashboard" />) : (
                    <Fragment>
                        <div className="container content my-4 register-page">
                            <div>
                                <div className="my-4">
                                    <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="sign">✍🏻</span> User Register</h1>
                                    <p className="lead text-center">Please, fill out the from down below:</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                    <label htmlFor="email"><span role="img" aria-label="envelop">✉️</span> Email address*</label>
                                    <input onChange={(e) => handleChange(e)} value={email} name="email" type="email" className="form-control" id="email" aria-describedby="emailAddress" placeholder="Enter email" />
                                    <small id="emailAddress" className="form-text text-muted">Email will be hidden & private. Nobody will see it.</small>
                                    </div>
                                    <div className="form-group">
                                    <label htmlFor="password"><span role="img" aria-label="lock">🔒</span> Password*</label>
                                    <input onChange={(e) => handleChange(e)} value={password} name="password" type="password" className="form-control" id="password" placeholder="Password" />
                                    <small id="pass" className="form-text text-muted">Please, enter a password.</small>

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password2"><span role="img" aria-label="lock">🔒</span> Repeat Password*</label>
                                        <input onChange={(e) => handleChange(e)} value={password2} name="password2" type="password" className="form-control" id="password2" placeholder="Password" />
                                        <small id="pass2" className="form-text text-muted">Please, repeat you password entry.</small>
                                    </div>
                                    <div className="form-group">
                                    <label htmlFor="phone"><span role="img" aria-label="phone">📞</span> Phone number*</label>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            international
                                            defaultCountry="US"
                                            value={phone}
                                            className="form-control"
                                            id="phone"
                                            onChange={(phone) => handlePhone(phone)}
                                        />
                                    <small id="phonenum" className="form-text text-muted">Phone number will be hidden & private. Nobody will see it.</small>
                                    </div>
                                    { alert && alert.length > 0 && <Alert /> }
                                    <div className="text-center">
                                        { isLoading && <Spinner size={50} /> }
                                        <button type="submit" className="btn btn-primary mt-4" disabled={isLoading}>
                                        { isLoading ? (<span>Loading...</span>) : (<span>Submit</span>) }
                                        </button>
                                        <PrivacyNotice />
                                        <div className="my-3">
                                            <small>Have an account? <Link className="text-dark" to="/login"><u>Login.</u></Link></small>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                </Fragment>
                )
            }
        </Fragment>
    )
}

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    alert: state.alert
})

export default connect(mapStateToProps, { setAlert, registerUser })(withRouter(Register));