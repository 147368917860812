import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <footer id="footer" className="container-fluid footer text-center">
            <div className="row">
                <div className="col-lg-12 footer-menu">
                    <ul>
                        <li><HashLink className="btn btn-sm btn-outline-secondary mx-1" to="/how-it-works#start"><span className="link-text">How it works</span> <span role="img" aria-label="list">📋</span></HashLink></li>
                        <li><Link className="btn btn-sm btn-outline-secondary mx-1" to="/login"><span className="link-text">Login</span> <span role="img" aria-label="waving-hand">👋</span></Link></li>
                        <li><Link className="btn btn-sm btn-outline-secondary mx-1" to="/register"><span className="link-text">Register</span> <span role="img" aria-label="writing-hand">✍️</span></Link></li>
                    </ul>
                </div>
                <div className="col-lg-12 text-center mx-auto">
                    <small className="text-center footer-email">Email: <a href="mailto:info@buddylist.me">hello@buddylist.me</a></small>
                    <small className="copyright text-center mt-3">2020 © BuddyList</small>
                    <small className="text-center">with <span role="img" aria-label="red-heart">❤️</span> by <a href="https://twitter.com/igorpiatkov" target="_blank" rel="noopener noreferrer">igorpiatkov</a></small>
                </div>
            </div>
            <div className="footer-policy mt-2 opacity-5">
                <span className="text-center"><HashLink to="/privacy-policy#start">Privacy Policy</HashLink></span> <span className="text-center"><HashLink to="/terms-of-service#start">Terms Of Service</HashLink></span>
            </div>
        </footer>
    )
}

export default Footer;