import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearLocation } from '../../actions/location';
import Spinner from '../layout/Spinner';
import { getCitiesByCountryAndState } from '../../actions/location';
import { editFormData } from '../../actions/formData';
import compareStrings from '../../utils/compareStrings';
import formatInput from '../../utils/formatInput';

const SelectState = ({ location:{ states }, data: { country, state, customState, customCity }, clearLocation, getCitiesByCountryAndState, editFormData }) => {
    
    useEffect(() => {
        if(state){
            // get all cities by selected country + id
            getCitiesByCountryAndState(country, state);
        }
    }, [state, getCitiesByCountryAndState])

    const handleChange = (e) => {
        if(e.target.name === "customState" || e.target.name === "customCity") {
            return editFormData({ [e.target.name]: e.target.value, city: '' }, { citySearched: '' });
        }

        // clearLocation(states, cities, countries)
        clearLocation(null, true);
        // if form state selection changes, reset formData.city & formData.citySearched
        editFormData({ [e.target.name]: e.target.value, city: '', customState: '', customCity: '' }, { citySearched: '' });
    }

    return (
        <Fragment>
            {
                states ? (
                    <Fragment>
                        <div className="form-group">
                            <label className="my-1 mr-2" htmlFor="state"><span role="img" aria-label="sunset">🌅</span> State (Region) </label>
                            <select onChange={(e) => handleChange(e)} value={state} name="state" className="custom-select my-1 mr-sm-2" id="state">
                            <option value='' defaultValue>Choose...</option>
                            {
                                states && states
                                .sort((a, b) => compareStrings(a.name, b.name))
                                .map(state => (
                                    <option value={state.id} key={state.id}>{state.name}</option>  
                                ))
                            }
                            {/* do not change the value="0" its important and specific only for "Other" state */}
                            <option value="0">Other (Enter your own)</option>
                            </select>
                            <small id="stateName" className="form-text text-muted">Select your state</small>
                        </div>
                        {
                            state === "0" && <Fragment>
                            <div className="form-group">
                                <label htmlFor="customState"><span role="img" aria-label="question-mark">❔</span> Enter Your State (Region)*</label>
                                <input onChange={(e) => handleChange(e)} onBlur={(e) => formatInput(e, editFormData)} value={customState} name="customState" type="state" className="form-control" id="customState" placeholder="Enter your state" required />
                                <small className="form-text text-muted">Please, specify your state. Example: "Saint Powl".</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="customCity"><span role="img" aria-label="question-mark">❔</span> Enter Your City*</label>
                                <input onChange={(e) => handleChange(e)} onBlur={(e) => formatInput(e, editFormData)} value={customCity} name="customCity" type="city" className="form-control" id="customCity" placeholder="Enter your city" required />
                                <small className="form-text text-muted">Please, specify your city. Example: "Browntown".</small>
                            </div>
                            </Fragment>
                        }
                    </Fragment>
                ) : (<Spinner />)
            }
        </Fragment>
    )
}

SelectState.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    clearLocation: PropTypes.func.isRequired,
    getCitiesByCountryAndState: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    location: state.location,
    data: state.formData.data
})

export default connect(mapStateToProps, { clearLocation, getCitiesByCountryAndState, editFormData })(SelectState);