import React, { Fragment, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../layout/Header';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreateProfileGeneral from '../profile-forms/CreateProfileGeneral';
import CreateProfileLocation from '../profile-forms/CreateProfileLocation';
import CreateProfileGenInterests from '../profile-forms/CreateProfileGenInterests';
import CreateProfileProfInterests from '../profile-forms/CreateProfileProfInterests';
import Spinner from '../layout/Spinner';
import { setAlert } from '../../actions/alert';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import { getCountries } from '../../actions/location';
import { loadUser } from '../../actions/auth';
import { setupFormData, editFormData } from '../../actions/formData';

const CreateProfile = ({ auth, profile: { profile, loading }, formData, formData: { data, settings } , createProfile, setAlert, loadUser, getCurrentProfile, getCountries, setupFormData, editFormData, history }) => {
    
    const initialState = {
        firstName: '',
        lastName: '',
        occupation: '',
        customOccupation: '',
        dob: '',
        gender: '',
        country: '',
        city: '',
        citySearched: '',
        customCity: '',
        state: '',
        customState: '',
        zip: '',
        profInterests: [],
        genInterests: []
    }

    const initialSettings = {
        citySearched: '',
        privacy: false,
        step: 1,
        isLoading: false,
        isFetching: false,
        avatar: {
            image: null,
            crop: {
                unit: "%",
                width: 50,
                height: 50,
                aspect: 1 / 1,
                x: 25,
                y: 0          
            },
            croppedAvatarUrl: null,
            croppedAvatar: null,
            avatarSizeExceed: false,
            avatarValidType: true,
            avatarPreview: false
        }
    }

    useEffect(() => {
        loadUser();
        getCurrentProfile();
        setupFormData(initialState, initialSettings);
        getCountries();
    }, [loadUser, getCurrentProfile, getCountries, setupFormData])


    const handleSubmit = (e) => {
        e.preventDefault();

        // turning on the Spinner image
        editFormData(null, { isLoading: true })

        // check if user has uploaded & cropped an avatar + auto-resized by (pica lib) 
        if(settings.avatar.croppedAvatar) {
            const avatar = new FormData();
            avatar.append('file', formData.settings.avatar.croppedAvatar)
            // creating user's profile + uploading avatar to AWS s3
            createProfile(data, avatar, history)      
        } else {
            // creating user's profile + uploading avatar to AWS s3
            createProfile(data, null, history)   
        }        
    }

    return (
        <div className="create-profile-form-component">
            {
                profile && !loading ? (<Redirect to="/dashboard" />) : (
                    !auth.user || auth.loading || loading || formData.loading || !data || !settings ? (<Spinner />) : (<Fragment>
                    <Header />
                    {
                        settings.step === 1 ? (<CreateProfileGeneral />) :
                        settings.step === 2 ? (<CreateProfileLocation />) :
                        settings.step === 3 ? (<CreateProfileProfInterests />) :
                        settings.step === 4 ? (<CreateProfileGenInterests handleSubmit={handleSubmit}/>) :
                        (<Spinner />)
                    }
                    </Fragment>)
                )
            }
        </div>
    )
}

CreateProfile.propTypes = {
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object,
    formData: PropTypes.object.isRequired,
    createProfile: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    setupFormData: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    profile: state.profile,
    auth: state.auth,
    formData: state.formData
})

export default connect(mapStateToProps, { createProfile, setAlert, loadUser, getCurrentProfile, setupFormData, editFormData, getCountries })(CreateProfile);