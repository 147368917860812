import { REGISTER_SUCCESS, REGISTER_FAIL, LOADUSER_FAIL, LOADUSER_SUCCESS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/types';

const initialState = {
    token: null,
    user: null,
    isAuthenticated: false,
    loading: true
}

export default function(state = initialState, action) {
    
    const { payload, type } = action;

    switch(type) {
        case LOADUSER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', payload.token)
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        case LOGOUT_SUCCESS:
        case LOGIN_FAIL:
        case LOADUSER_FAIL:
        case REGISTER_FAIL:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                loading: false
            }
        default:
            return state;
    }
}