import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import Header from './Header';

const NotFound = () => {
    return (
        <Fragment>
            <Header />
            <div className="text-center my-5">
                <h1>Page Not Found.</h1>
                <p className="lead">We're sorry! Looks like this page does not exist (404) <span role="img" aria-label="sad">🙁</span></p>
                <Link className="btn btn-secondary" to="/">Go Home</Link>
            </div>
        </Fragment>
    )
}

export default NotFound;