import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';


const SignedOutLinks = () => {
    return (
        <Fragment>
            <Link to="/register" className="text-white mx-2 h6">Register</Link>
            <Link to="/login" className="text-white mx-2 h6">Login</Link>
        </Fragment>
    )
}


export default SignedOutLinks;

