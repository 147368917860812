import React, { Fragment } from 'react';
import Alert from '../layout/Alert';
import AvatarUpload from './AvatarUpload';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editFormData, formNextStep, formPrevStep } from '../../actions/formData';
import { setAlert } from '../../actions/alert';
import occupations from '../../data/profile/occupations.json'
import compareStrings from '../../utils/compareStrings';
import formatInput from '../../utils/formatInput';

const CreateProfileGeneral = ({ editFormData, formData: { loading, data: { firstName, lastName, dob, gender, occupation, customOccupation }, settings: { avatar: { avatarPreview } } }, formNextStep, setAlert, alert }) => {

    const handleChange = (e) => {
        editFormData({ [e.target.name]: e.target.value }, null)
    }

    const handleClick = () => {
        if(!firstName || !lastName || !dob || !gender || !occupation || occupation === "0" && !customOccupation) {
            return setAlert('Please, fill out all required fields.', 'danger');
        }
        
        formNextStep()
    }

    return (
        <Fragment>
            <div className="container content my-4">
            { !avatarPreview && (
                <div>
                    <div className="my-4">
                        <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="list">📋</span> Profile Setup (Step 1)</h1>
                        <p className="lead text-center">Please, fill out the from down below:</p>
                    </div>
                        <form>
                            <div className="form-group">
                            <label htmlFor="firstName"><span role="img" aria-label="first">1️⃣</span> First Name*</label>
                            <input onChange={(e) => handleChange(e)} onBlur={(e) => formatInput(e, editFormData)} value={firstName} name="firstName" type="text" className="form-control" id="firstName" aria-describedby="firstNameHelp" placeholder="First Name" required />
                            </div>
                            <div className="form-group">
                            <label htmlFor="lastName"><span role="img" aria-label="second">2️⃣</span> Last Name*</label>
                            <input onChange={(e) => handleChange(e)} onBlur={(e) => formatInput(e, editFormData)} value={lastName} name="lastName" type="text" className="form-control" id="lastName" placeholder="Last Name" required />
                            </div>
                            <div className="form-group">
                                <label className="my-1 mr-2" htmlFor="occupation"><span role="img" aria-label="suitcase">💼</span> Occupation*</label>
                                <select onChange={(e) => handleChange(e)} value={occupation} name="occupation" className="custom-select my-1 mr-sm-2" id="occupation" required>
                                    <option value='' defaultValue>Choose...</option>
                                    {
                                        occupations
                                        .sort((a, b) => compareStrings(a.occupation_title, b.occupation_title))
                                        .map((item, index) => (
                                            <option key={index} value={item.id}>{item.occupation_title}</option>
                                        ))
                                    }
                                    {/* do not change the value="0" its important and specific only for "Other" occupation */}
                                    <option value="0">Other (Enter your own)</option>
                                  </select>
                                <small id="occupation" className="form-text text-muted">Select your occupation or something similar to it.</small>
                            </div>
                            {
                                occupation === "0" && <div className="form-group">
                                    <label htmlFor="customOccupation"><span role="img" aria-label="question-mark">❔</span> Other Occupation*</label>
                                    <input onChange={(e) => handleChange(e)} onBlur={(e) => formatInput(e, editFormData)} value={customOccupation} name="customOccupation" type="occupation" className="form-control" id="customOccupation" placeholder="Enter your occupation" required />
                                    <small className="form-text text-muted">Please, specify your occupation. Example: "Rocket Developer".</small>
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="dob"><span role="img" aria-label="child">👶</span> Date Of Birth*</label>
                                <input onChange={(e) => handleChange(e)} value={dob} name="dob" type="date" className="form-control" id="dob" aria-describedby="DateOfBirthNameHelp" placeholder="Example: 05/20/1990" required />
                                <small id="date-of-birth" className="form-text text-muted">Format: month/date/year</small>
                            </div>
                            <div className="form-group">
                                <label className="my-1 mr-2" htmlFor="gender"><span role="img" aria-label="masks">🎭</span> Gender*</label>
                                <select onChange={(e) => handleChange(e)} value={gender} name="gender" className="custom-select my-1 mr-sm-2" id="gender" required>
                                    <option value='' defaultValue>Choose...</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                    <option value="3">Other</option>
                                </select>
                            </div>
                        </form>
                </div>
            ) }
                    <AvatarUpload />
                    { alert && alert.length > 0 && <Alert /> }
                    { !loading && !avatarPreview && <div className="text-center">
                        <button type="button" className="btn btn-primary mt-4" disabled={avatarPreview} onClick={handleClick}>Next</button>
                        <div className="my-2">Step: 1/4</div>
                    </div> }
            </div>
        </Fragment>
    )
}

CreateProfileGeneral.propTypes = {
    formData: PropTypes.object.isRequired,
    editFormData: PropTypes.func.isRequired,
    formNextStep: PropTypes.func.isRequired,
    formPrevStep: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    formData: state.formData,
    alert: state.alert
})

export default connect(mapStateToProps, { editFormData, formNextStep, formPrevStep, setAlert })(CreateProfileGeneral);