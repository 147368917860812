// alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
// register
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
// users
export const LOADUSER_SUCCESS = 'LOADUSER_SUCCESS';
export const LOADUSER_FAIL = 'LOADUSER_FAIL';
// profile
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const GET_PROFILES_FAIL = 'GET_PROFILES_FAIL';
export const PROFILES_COUNT_SUCCESS = 'PROFILES_COUNT_SUCCESS';
export const PROFILES_COUNT_FAIL = 'PROFILES_COUNT_FAIL';
export const GET_AVATARS = 'GET_AVATARS';
export const GET_AVATARS_FAIL = 'GET_AVATARS_FAIL';
// login
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// locations
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAIL = 'GET_STATES_FAIL';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';
export const CLEAR_LOCATION_STATES = 'CLEAR_LOCATION_STATES';
export const CLEAR_LOCATION_CITIES = 'CLEAR_LOCATION_CITIES';
// upload validation
export const UPLOAD_VALIDATION_FAIL = 'UPLOAD_VALIDATION_FAIL';
export const UPLOAD_VALIDATION_SUCCESS = 'UPLOAD_VALIDATION_SUCCESS';
export const UPLOAD_VALIDATION_ERROR = 'UPLOAD_VALIDATION_ERROR';
// crop
export const SETUP_CROP = 'SETUP_CROP';
export const SETUP_CROP_ERROR = 'SETUP_CROP_ERROR';
// formData
export const SETUP_FORM_DATA_SUCCESS = 'SETUP_FORM_DATA_SUCCESS';
export const SETUP_FORM_DATA_FAIL = 'SETUP_FORM_DATA_FAIL';
export const EDIT_FORM_DATA_SUCCESS = 'EDIT_FORM_DATA_SUCCESS';
export const EDIT_FORM_DATA_FAIL = 'EDIT_FORM_DATA_FAIL';
export const FORM_NEXT_STEP_SUCCESS = 'FORM_NEXT_STEP_SUCCESS';
export const FORM_PREV_STEP_SUCCESS = 'FORM_PREV_STEP_SUCCESS';
export const FORM_STEP_FAIL = 'FORM_STEP_FAIL';
export const EDIT_FORM_DATA_SETTINGS_SUCCESS = 'EDIT_FORM_DATA_SETTINGS_SUCCESS';
export const EDIT_FORM_DATA_SETTINGS_FAIL = 'EDIT_FORM_DATA_SETTINGS_FAIL';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

