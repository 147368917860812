import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { userLogout } from '../../actions/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const SignedInLinks = ({ userLogout }) => {

    const handleClick = (e) => {
        e.preventDefault();
        userLogout();
    }

    return (
        <Fragment>
            <Link to="#!" className="text-white mx-2 h6" onClick={(e) => handleClick(e)}>Logout</Link>
        </Fragment>
    )
}

SignedInLinks.propTypes = {
    userLogout: PropTypes.func.isRequired
}

export default connect(null, { userLogout })(SignedInLinks);

