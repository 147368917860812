import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Footer from '../layout/Footer';
import Users from './Users';
import By from '../layout/By';
import Spinner from '../layout/Spinner';
import twitter from './twitter-icon.png';
import facebook from './facebook-icon.png';
import howItWorks from './how-it-works.png';
import tinder from './tinder-icon.png';
import { getProfilesCount } from '../../actions/profile';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomTooltip from '../layout/CustomTooltip';
import { getAvatars } from '../../actions/profile';
import SignedInLinks from '../auth/SignedInLinks';
import SignedOutLinks from '../auth/SignedOutLinks';

const Landing = ({ profiles: { total, avatars }, auth: { user, loading }, getProfilesCount, getAvatars, config }) => {

    useEffect(() => {
        getAvatars();
        getProfilesCount();
    }, [getProfilesCount, getAvatars])

    const WhyTooltip = "It's hard to raffle users between each other when there are only few users on the platform. I'd be happy 🙂 if you help me to launch the project faster, please, consider joining and sharing 🙏."

    return (
        <Fragment>
            <div className="container-fluid header">
                <div className="row hero">
                    <div className="col-md-8 offset-md-2 text-center">
                        <Link className="logo" to="/">BuddyList</Link>
                    </div>
                    { !loading && <div className="col-md-2 text-center nav-menu">
                        { user ? <SignedInLinks /> : <SignedOutLinks /> }
                    </div> }
                    <div className="col-md-12 launch-counter mt-5">
                        <span> Launch <span role="img" aria-label="rocket">🚀</span> at { total ? total : '?' } / 5 000 users <CustomTooltip title="why?🤔" text={WhyTooltip} /></span>
                    </div>
                    { config && <div className="col-md-12 share text-center my-1 display-4">help us: <a href={config.shareLinks.twitter} target="_new" rel="noopener noreferrer" className="btn share-icon mx-1"><img src={twitter} alt="twitter" /></a><a href={config.shareLinks.facebook} target="_new" rel="noopener noreferrer" className="btn share-icon mx-1"><img src={facebook} alt="facebook" /></a></div> }
                    <div className="col-md-12 welcome mt-4">
                        <h1 className="title h1">Join Our List <span role="img" aria-label="list">📋</span></h1>
                        <p className="description m-0 lead">BuddyList — it's like <img src={tinder} alt="tinder" className="tinder-logo" />, but for professional connections in tech.</p>
                        <div className="controls my-3">
                            <Link to="/register" className="btn btn-success btn-lg my-2 mx-2">Join/Register</Link>
                            <HashLink to="/how-it-works#start" className="btn btn-light btn-lg my-2 mx-2">How It Works</HashLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="container content my-5 text-center">
                    <div id="how-it-works" className="my-5">
                        <h2 className="col-md-12 title text-center h1">How It Works</h2>
                        <p className="lead text-center desc">Take a look at the schema down below to get a better understanding of our BuddyList system. Feel free to join and try it out by yourself!</p>
                        <img className="how-it-works-schema" src={howItWorks} alt="how-it-works-schema"/>
                        <div className="call-to-action text-center my-3">
                            <HashLink to="/how-it-works#start" className="btn btn-secondary">Learn More</HashLink>
                        </div>
                    </div>
                    <h2 className="col-md-12 title text-center h1">Meet Our Users</h2>
                    <p className="lead text-center desc">Our community includes a lot of professionals and talent people. Meet a buddy from your current city, get friends, build businesses, or even launch start-ups together!</p>
                    { avatars ? <Users /> : avatars && avatars.length < 1 ? (<h3>Users not found...</h3>) : <Spinner /> }
                    {/* <div className="text-center"><small>+ many more...</small></div> */}
                </div>
                <div className="call-to-action text-center my-5">
                    <p className="lead desc">Ready to get new connections?</p>
                    <Link to="/register" className="btn btn-success">Join Now</Link>
                </div>
            </div>
            <Footer />
            <By />
        </Fragment>
    )
}

Landing.propTypes = {
    profiles: PropTypes.object.isRequired,
    getProfilesCount: PropTypes.func.isRequired,
    getAvatars: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    profiles: state.profile.profiles,
    auth: state.auth,
    config: state.config
})

export default connect(mapStateToProps, { getProfilesCount, getAvatars })(Landing);