import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import SelectCountry from './SelectCountry';
import SelectState from './SelectState';
import SelectCity from './SelectCity';
import { setAlert } from '../../actions/alert';
import Alert from '../layout/Alert';
import { formNextStep, formPrevStep, editFormData } from '../../actions/formData';

const CreateProfileLocation = ({ location: { countries, cities, states }, formData: { data: { country, state, city, zip, customCity, customState }, settings: { isFetching } }, editFormData, formNextStep, formPrevStep, setAlert, alert }) => {
    
    const handleChange = (e) => {
        editFormData({ [e.target.name]: e.target.value }, null)
    }

    const handleClick = () => {
        
        if(!country) {
            return setAlert('Country field is required. Please, select a country.', 'danger');
        }

        if(state === "0" && !customState || state === "0" && !customCity) {
            return setAlert('Please, fill out state and city fields.', 'danger');
        }

        formNextStep()
    }

    return (
        <Fragment>
            {
                countries ? (
                    <div className="container content my-4">
                        <div>
                            <div className="my-4">
                                <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="city">🌇</span>Location (Step 2)</h1>
                                <p className="lead text-center">Please, fill out the from down below:</p>
                            </div>
                            <form>
                                { <SelectCountry />}
                                { country && states && states.length > 0 && <SelectState />}
                                { state && cities && cities.length > 0 && <SelectCity />}
                                { country === "233" && city && 
                                    <div className="form-group">
                                        <label htmlFor="zip"><span role="img" aria-label="cactus">🌵</span> ZIP Code</label>
                                        <input onChange={(e) => handleChange(e)} value={zip} name="zip" type="name" className="form-control" id="zip" aria-describedby="zipNameHelp" placeholder="Enter ZIP" />
                                        <small id="zipCode" className="form-text text-muted">Example: 10002</small>
                                    </div> }
                                { alert && alert.length > 0 && <Alert /> }
                                { isFetching && <Spinner /> }
                                <div className="text-center">
                                    <button type="button" className="btn btn-secondary mt-4 mx-1" onClick={formPrevStep}>Back</button>
                                    <button type="button" className="btn btn-primary mt-4 mx-1" onClick={handleClick} disabled={isFetching}>Next</button>
                                    <div className="my-2">Step: 2/4</div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (<Spinner />)
            }
        </Fragment>
    )
}

CreateProfileLocation.propTypes = {
    location: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    formPrevStep: PropTypes.func.isRequired,
    formNextStep: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    location: state.location,
    formData: state.formData,
    alert: state.alert
})

export default connect(mapStateToProps, { formNextStep, formPrevStep, editFormData, setAlert })(CreateProfileLocation);