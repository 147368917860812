import React, { Fragment, useState } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Header from '../layout/Header';
import Spinner from '../layout/Spinner';
import Alert from '../layout/Alert';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { userLogin } from '../../actions/auth';
import PropTypes from 'prop-types';

const Login = ({ auth: { isAuthenticated, loading }, alert, setAlert, userLogin, history }) => {

    const initialState = {
        email: '',
        password: '',
        isLoading: false
    }

    const [ formData, setFormData ] = useState(initialState)

    const  { email, password, isLoading } = formData;

    const handleChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({ ...formData, isLoading: true });

        userLogin(formData, setFormData, history);
    }

    return (
        <Fragment>
            <Header />
            {
                loading ? (<Spinner />) : isAuthenticated ? (<Redirect to="/dashboard" />) : (
                <Fragment>
                    <div className="container content my-4 login-page">
                        <div>
                            <div className="my-4">
                                <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="sign">✍🏻</span> User Login</h1>
                                <p className="lead text-center">Please, fill out the from down below:</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                <label htmlFor="email"><span role="img" aria-label="envelop">✉️</span> Email address</label>
                                <input onChange={(e) => handleChange(e)} value={email} name="email" type="email" className="form-control" id="email" aria-describedby="emailAddress" placeholder="Enter email" />
                                </div>
                                <div className="form-group">
                                <label htmlFor="password"><span role="img" aria-label="lock">🔒</span> Password</label>
                                <input onChange={(e) => handleChange(e)} value={password} name="password" type="password" className="form-control" id="password" placeholder="Password" />
                                </div>
                                { alert && alert.length > 0 && <Alert /> }
                                <div className="text-center">
                                    { isLoading && <Spinner size={50} /> }
                                    <button type="submit" className="btn btn-success mt-4" disabled={isLoading}>
                                        { isLoading ? (<span>Loading...</span>) : (<span>Login</span>) }
                                    </button>
                                    <div className="my-3">
                                    <small>Don't have an account? <Link className="text-dark" to="/register"><u>Register.</u></Link></small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>
                )
            }
        </Fragment>
    )
}

Login.propTypes = {
    setAlert: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    alert: state.alert
})

export default connect(mapStateToProps, { setAlert, userLogin })(withRouter(Login));