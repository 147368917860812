import React, { Fragment, useEffect } from 'react';
import Spinner from '../layout/Spinner';
import Alert from '../layout/Alert';
import PrivacyNotice from '../layout/PrivacyNotice';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { formPrevStep, editFormData } from '../../actions/formData';
import allGenInterests from '../../data/profile/genInterests.json'

const CreateProfileGenInterests = ({ formData: { data:{ genInterests }, settings: { isLoading, privacy } }, formPrevStep, editFormData, handleSubmit, setAlert, alert }) => {

    let interests = genInterests;

    useEffect(() => {
        const checkboxes = document.querySelectorAll('.form-check input');

        checkboxes.forEach(check => {
            if(interests.includes(check.value)) {
                check.setAttribute("checked", "true")
            }
        })
    }, [interests])

    const handleCheck = (e) => {
        if(e.target.name === 'privacy') {
            return editFormData(null, { privacy: !privacy });
        }

        if(interests.includes(e.target.value)) {
            interests = interests.filter(item => item !== e.target.value)
        } else {
            interests.push(e.target.value)
        }

        editFormData({ genInterests: interests }, null);
    }

    const handleClick = (e) => {
        if(genInterests.length < 1) {
            return setAlert('Please, select at least one interest.', 'danger');
        }

        handleSubmit(e)
    }
    
    // generate checkboxes from local json file by filtering them by categorie_id = 'General'
    const generateCheckboxes = () => {
        // map over all genCategories and place them on the page
        return allGenInterests
        .map((item) => (               
            <div key={item.id} className="form-check form-check-inline m-2">
                <input onClick={handleCheck} name={item.id} className="form-check-input" type="checkbox" value={item.id} id={item.id}/>
                <label className="form-check-label" htmlFor={item.id}>
                {item.interest_title}
                </label>
            </div>
        ))
    }

    return (
        <Fragment>
            <div className="container content my-4">
                <div>
                    <div className="my-4">
                        <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="diamond">⚽️</span> General Interests/Hobbies (Step 4)</h1>
                        <p className="lead text-center mb-5">We just want to make sure your buddy fit you as much as possible. These parameters will not have a huge impact on the result, but will affect positively.</p>
                    </div>
                    <h2 className="col-md-12 title text-center h2">Choose (if apply to you):</h2>
                    <form className="interest-form">
                        {generateCheckboxes()}
                        { alert && alert.length > 0 && <Alert /> }
                        <div className="text-center">
                            { isLoading && <Spinner size={50} /> }
                            { !isLoading && <button type="button" className="btn btn-secondary mt-4 mx-1" onClick={formPrevStep}>Back</button> }
                            <button type="button" className="btn btn-success mt-4 mx-1" disabled={isLoading} onClick={(e) => handleClick(e)}>
                                { isLoading ? (<span>Loading...</span>) : (<span>Sign Up</span>)}
                            </button>
                            <PrivacyNotice />
                            <div className="my-2">Step: 4/4</div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

CreateProfileGenInterests.propTypes = {
    formData: PropTypes.object.isRequired,
    formPrevStep: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStatToProps = state => ({
    formData: state.formData,
    alert: state.alert
})

export default connect(mapStatToProps, { formPrevStep, editFormData, setAlert })(CreateProfileGenInterests);