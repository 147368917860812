import React, { Fragment } from 'react';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadValidationFail, uploadValidationSuccess, setupCrop } from '../../actions/formData';
import FileType from 'file-type';
import AvatarCrop from './AvatarCrop';

const AvatarUpload = ({ avatar: { image, avatarSizeExceed, avatarValidType, avatarPreview }, setAlert, uploadValidationFail, uploadValidationSuccess, setupCrop }) => {


    const handleFile = (e) => {

        // resetting previous avatar variables in case it wasn't empty
        setupCrop({ image: null, crop: { unit: "%", width: 50, height: 50, aspect: 1 / 1, x: 25, y: 0 } })
   
        // converting bytes to MB
        const fileSize = e.target.files.length > 0 && e.target.files[0].size / 1024 / 1024;
        
        // check if uploaded file size is over 2MB
        if(fileSize > 2) {
            uploadValidationFail({ avatarSizeExceed: true, avatarValidType: true })
            return setAlert('The file size can\'t exceed 2MB.', 'danger')
        }
        
        // check if file type is jpg or png by pulling out file's buffer
        const file = e.target.files[0];
        
        fileToBuffer(e)
        .then( async(buffer) => {
            try {
                const type = await FileType.fromBuffer(buffer);

                // validating file type
                if(type.ext !== 'jpg' && type.ext !== 'png') {
                    // dispatching the fail to fail the upload and reset state
                    await uploadValidationFail({ avatarSizeExceed: false, avatarValidType: false });
                    // returning the function
                    return setAlert('Only JPEG/PNG allowed.', 'danger');
                }

                // if suploaded file is valid then keep processing the file
                const fileReader = new FileReader()
                fileReader.onloadend = () => {
                    uploadValidationSuccess({ image: fileReader.result, avatarSizeExceed: false, avatarValidType: true, avatarPreview: true })
                }
                fileReader.readAsDataURL(file)
            } catch (err) {
                console.log(err.message)
            }
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    // turn uploaded file into a buffer (this func. is a part of file type validation)
    const fileToBuffer = (e) => {
        const file = e.target.files[0];
    
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                resolve(e.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsArrayBuffer(file);
        });
    }

    return (
        <div className="form-group avatar-upload">
            { !avatarPreview &&
                <Fragment>
                    <label className="my-1 mr-2" htmlFor="avatar"><span role="img" aria-label="smile">🙂</span> Profile Picture</label>
                    <small className="form-text text-muted mb-2">Real photo is preffered.</small>
                    <div className="custom-file">
                        <input onChange={(e) => handleFile(e)} name="avatar" type="file" className="custom-file-input" id="avatar" />
                        <label className="custom-file-label" htmlFor="avatar">{ image ? (<Fragment><span role="img" aria-label="check-mark">✔️</span> Success...</Fragment>) : avatarSizeExceed ? (<Fragment><span role="img" aria-label="cross">❌</span> Failed (file size is over 2MB)</Fragment>) : !avatarValidType ? (<Fragment><span role="img" aria-label="cross">❌</span> Failed (only jpg/png file format allowed)</Fragment>) : ('Upload a picture...') }</label>
                    </div>                
                { avatarSizeExceed ? (<small className="form-text text-red"><span role="img" aria-label="cross">❌</span> The file size can't exceed 2MB.</small>) : !avatarValidType ? (<small className="form-text text-red"><span role="img" aria-label="cross">❌</span> Only jpg/png file format allowed</small>) : (<small className="form-text text-muted">JPG/PNG file, size less than 2MB.</small>)}
            </Fragment>
            }
            { image && avatarPreview && <AvatarCrop /> }
        </div>
    )
}

AvatarUpload.propTypes = {
    avatar: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    uploadValidationFail: PropTypes.func.isRequired,
    uploadValidationSuccess: PropTypes.func.isRequired,
    setupCrop: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    avatar: state.formData.settings.avatar
})
 
export default connect(mapStateToProps, { setAlert, uploadValidationFail, uploadValidationSuccess, setupCrop })(AvatarUpload);