import React from 'react'
import { HashLink } from 'react-router-hash-link';

const PrivacyNotice = () => {
    return (
        <div className="privacy-agree mt-4 mb-2 mx-auto">
            <span>By signing up, you agree to our  <HashLink to="/terms-of-service#start" target="_new" rel="noopener noreferrer">Terms</HashLink> , <HashLink to="/privacy-policy#start" target="_new" rel="noopener noreferrer">Data Policy</HashLink> and <HashLink to="/privacy-policy#cookies" target="_new" rel="noopener noreferrer">Cookies Policy</HashLink>. You may receive SMS notifications from us and can opt out any time.</span>
        </div>
    )
}


export default PrivacyNotice;

