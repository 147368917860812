import axios from 'axios';
import { GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL, GET_STATES_SUCCESS, GET_STATES_FAIL, GET_CITIES_SUCCESS, GET_CITIES_FAIL, CLEAR_LOCATION_STATES, CLEAR_LOCATION_CITIES, EDIT_FORM_DATA_SETTINGS_SUCCESS } from './types';

// Get all countries
export const getCountries = () => async dispatch => {

    const res = await axios.get('/api/location/countries');

    try {
        dispatch({
            type: GET_COUNTRIES_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_COUNTRIES_FAIL
        })
    }
}

// Get all states in given country (by country id)
export const getStates = (country_id) => async dispatch => {

    // adding loading spinner
    dispatch({
        type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
        payload: { isFetching: true }
    })

    const res = await axios.get(`/api/location/states/${country_id}`);

    try {
        dispatch({
            type: GET_STATES_SUCCESS,
            payload: res.data
        })

        // removing loading spinner
        dispatch({
            type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
            payload: { isFetching: false }
        })
        
    } catch (err) {
        dispatch({
            type: GET_STATES_FAIL
        })

        // removing loading spinner
        dispatch({
            type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
            payload: { isFetching: false }
        })
    }
}

// Get cities by country id

// Get all cities by country id + state id
export const getCitiesByCountryAndState = (country_id, state_id) => async dispatch => {
    // adding loading spinner
    dispatch({
        type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
        payload: { isFetching: true }
    })
    

    const res = await axios.get(`/api/location/cities/${country_id}/${state_id}`);

    try {
        dispatch({
            type: GET_CITIES_SUCCESS,
            payload: res.data
        })
        // removing loading spinner
        dispatch({
            type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
            payload: { isFetching: false }
        })
    } catch (err) {
        dispatch({
            type: GET_CITIES_FAIL
        })
        // removing loading spinner
        dispatch({
            type: EDIT_FORM_DATA_SETTINGS_SUCCESS,
            payload: { isFetching: false }
        })
    }
}

export const clearLocation = (states, cities, countries) => dispatch => {
    if(states) {
        dispatch({
            type: CLEAR_LOCATION_STATES
        })
    } 
    
    if (cities) {
        dispatch({
            type: CLEAR_LOCATION_CITIES
        })
    }
    
}