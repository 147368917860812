import React, { Fragment } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const CustomTooltip = ({ title, text }) => {

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  return (
    <Fragment>
      <OverlayTrigger
      placement="bottom"
      delay={{ show: 150, hide: 400 }}
      overlay={renderTooltip}
      >
      <span className="why btn btn-sm ml-1">{title}</span>
    </OverlayTrigger>
  </Fragment>
  )
};

export default CustomTooltip;