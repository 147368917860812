import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignedInLinks from '../auth/SignedInLinks';
import SignedOutLinks from '../auth/SignedOutLinks';

const Header = ({ auth: { loading, user } }) => {
    return (
        <Fragment>
            <div className="container-fluid header">
                <div className="row hero">
                    <div className="col-md-8 offset-md-2 text-center">
                        <Link className="logo" to="/">BuddyList</Link>
                    </div>
                    { !loading && <div className="col-md-2 text-center nav-menu">
                        { user ? <SignedInLinks /> : <SignedOutLinks /> }
                    </div> }
                </div>
            </div>
        </Fragment>
    )
}

Header.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, null)(Header);