import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formNextStep, formPrevStep } from '../../actions/formData';
import { editFormData } from '../../actions/formData';
import { setAlert } from '../../actions/alert';
import Alert from '../layout/Alert';
import allProfInterests from '../../data/profile/profInterests.json'
import interestCategories from '../../data/profile/interestCategories.json'

const CreateProfileProfInterests = ({ data: { profInterests }, formNextStep, formPrevStep, editFormData, setAlert, alert }) => {

    let interests = profInterests;

    useEffect(() => {
        const checkboxes = document.querySelectorAll('.form-check input');

        checkboxes.forEach(check => {
            if(interests.includes(check.value)) {
                check.setAttribute("checked", "true")
            }
        })
    }, [interests])

    const handleCheck = (e) => {
        if(interests.includes(e.target.value)) {
            interests = interests.filter(item => item !== e.target.value)
        } else {
            interests.push(e.target.value)
        }

        editFormData({ profInterests: interests }, null);
    }

    const handleClick = () => {
        if(profInterests.length < 1) {
            return setAlert('Please, select at least one interest.', 'danger');
        }

        formNextStep()
    }

    // generate checkboxes from local json file
    const generateCheckboxes = () => {
        // map all categories (h3 titles) from local json file & loop(.map) all checkboxes for each categories inside
        return interestCategories.map((item, index) => (
            item.id !== 7 &&
            <div key={item.id}>
                {/* generate all categorie_titles which are not equal to 7 - "General" categorie */}
                <h3 className="title h3 mt-5">{item.id !== 7 && item.categorie_title}</h3>
                <div>
                    {
                        // filter interests(checkboxes) by categorie to group them under h3 categorie_title & .map over them
                        allProfInterests
                        .filter((item) => item.categorie_id === index + 1)
                        .map((item) => (               
                            <div key={item.id} className="form-check form-check-inline m-2">
                                <input onClick={handleCheck} name={item.id} className="form-check-input" type="checkbox" value={item.id} id={item.id}/>
                                <label className="form-check-label" htmlFor={item.id}>
                                {item.interest_title}
                                </label>
                            </div>
                        ))

                    }
                </div>
            </div>
        ))
    }

    return (
        <Fragment>
            <div className="container content my-4">
                <div>
                    <div className="my-4">
                        <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="diamond">🎓</span> Professional Interests (Step 3)</h1>
                        <p className="lead text-center mb-5">Professional interests are crusial for matching you a person, the matching results will be based mostly on these interests, so be careful with your selections.</p>
                    </div>
                    <h2 className="col-md-12 title text-center h2">Choose (if apply to you):</h2>
                    <p className="lead text-center my-2">Please check <u>only</u> what applies to you <span role="img" aria-label="exclamation-mark">❗️</span></p>
                    <form className="interest-form">
                        {generateCheckboxes()}
                        { alert && alert.length > 0 && <Alert /> }
                        <div className="text-center">
                            <button type="button" className="btn btn-secondary mt-4 mx-1" onClick={formPrevStep} >Back</button>
                            <button type="button" className="btn btn-primary mt-4 mx-1" onClick={handleClick} >Next</button>
                            <div className="my-2">Step: 3/4</div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

CreateProfileProfInterests.propTypes = {
    data: PropTypes.object.isRequired,
    formNextStep: PropTypes.func.isRequired,
    formPrevStep: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    alert: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    data: state.formData.data,
    alert: state.alert
})

export default connect(mapStateToProps, { formNextStep, formPrevStep, editFormData, setAlert })(CreateProfileProfInterests);