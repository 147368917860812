import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearLocation } from '../../actions/location';
import { getStates } from '../../actions/location';
import { editFormData } from '../../actions/formData';
import compareStrings from '../../utils/compareStrings';

const SelectCountry = ({ location: { countries }, data: { country }, clearLocation, getStates, editFormData }) => {
    useEffect(() => {
        // get all states by selected country
        getStates(country);
        if(!country) {
            editFormData(null, { isFetching: false })
        }
    }, [country, getStates])

    const handleChange = (e) => {
        // clearLocation(states, cities, countries)
        clearLocation(true, true);
        // if form country selection changes, reset formData.state & formData.city & formData.citySearched
        editFormData({ country: e.target.value, state: '', city: '', customState: '', customCity: '' }, { citySearched: '' });
    }

    return (
        <Fragment>
            <div className="form-group">
                <label className="my-1 mr-2" htmlFor="country"><span role="img" aria-label="map">🗾</span> Country*</label>
                <select onChange={(e) => handleChange(e)} value={country} name="country" className="custom-select my-1 mr-sm-2" id="country" required>
                    <option value='' defaultValue>Choose...</option>
                    { countries && countries
                    .sort((a, b) => compareStrings(a.name, b.name))
                    .map(country => (
                        <option value={country.id} key={country.id}>{country.name}</option>
                    )) }
                </select>
                <small id="countryName" className="form-text text-muted">Select your country</small>
            </div>
        </Fragment>
    )
}

SelectCountry.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    clearLocation: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    editFormData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    location: state.location,
    data: state.formData.data
})

export default connect(mapStateToProps, { clearLocation, getStates, editFormData })(SelectCountry);