import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import By from '../layout/By';
import tinder from '../landing/tinder-icon.png';
import howItWorks from '../landing/how-it-works.png';

const HowItWorks = () => {
    return (
        <div id="start">
            <Header />
            <div className="main-wrapper how-it-works-page">
            <div className="container content my-5">
                <div className="my-5">
                    <h1 className="col-md-12 title text-center h1">How It Works</h1>
                    <p className="lead text-center mb-5">BuddyList — it's like <img src={tinder} alt="tinder" className="tinder-logo" />, but for professional connections in tech! <br /> Keep reading this page if you want to learn more about how BuddyList works.</p>
                    <div className="py-1 mt-5">
                        <h2 className="h4 mb-4">Why? <span role="img" aria-label="cowboy">🤠</span></h2>
                        <p className="ml-3 text-section-border">As we grow, it becomes harder to make new friends and social connections, especially when you want to surround yourself with people like yourself, people with similar interests, and goals. In the tech field, everybody tends to be introverted and being solo most of the time, but that's not the best practice not just for life, but also for career and business <span role="img" aria-label="rocket">🚀</span>! I created this platform to help people make helpful connections, find friends, and even business or startup partners. Welcome to BuddyList!</p>
                    </div>
                    <div className="mt-5 py-1">
                        <h2 className="h4 mb-4">For whom? <span role="img" aria-label="programmer">👨‍💻</span></h2>
                        <p className="ml-3 text-section-border">The platform is focused on people in tech <span role="img" aria-label="programmer">👨‍💻</span>, so basically everybody from a developer, designer to CEO, and founder. I would like to not just connect people online, but I want them to connect <b><u>in real life</u></b>, and that's a really important aspect of this whole project! As more users you meet in person, the more your BuddyList rating will grow the better your experience will be.</p>
                    </div>
                    <div className="mt-5 py-1">
                        <h2 className="h4 mb-4">How to start? <span role="img" aria-label="writing-hand">✍️</span></h2>
                        <p className="ml-3 text-section-border">
                            <ol>
                                <li className="py-1">Register and fill out the registration form as much as you can, as accurately as you can.</li>
                                <li className="py-1">The matching system will scan <span role="img" aria-label="magnifier">🔍</span> all BuddyList users to find people similar to you — users, who are about your age, who share your professional interests, occupation, hobbies, primary location, and more.</li>
                                <li className="py-1">As soon as it finds a match <span role="img" aria-label="target">🎯</span> you will get a new notification, and will able to view the person’s profile to decide if you want to reject or accept the match.</li>
                                <li className="py-1">If both of you accepted the match request, you will be transfered to the BuddyList private chat so you can communicate <span role="img" aria-label="speech-baloon">💬</span> with each other.</li>
                                <li className="py-1">Meet your buddy in real life. The main purpose of online chat is to decide whether you feel comfortable meeting the person in real life or not. You will not be able to start a new buddy search before you finish with the current one. To grow your rating on the BuddyList platform you have to meet your buddies in-person or decline the match and move to the next one.</li>
                            </ol>
                        </p>
                    </div>
                    <div className="text-center"><img className="how-it-works-schema" src={howItWorks} alt="how-it-works-schema"/></div>
                    <div id="faq" className="my-3 py-5">
                        <h2 className="col-md-12 title text-center h1">FAQ</h2>
                        <p className="lead text-center mb-5">Frequently Asked Questions.</p>
                        <div className=" py-1">
                            <h2 className="h4 mb-4">How often will I get matches? <span role="img" aria-label="pull-8-ball">🎱</span></h2>
                            <p className="ml-3 text-section-border">You will able to get a new match as soon as you finish your current matching process. In order for you to finish it, you have to meet the person in real life or reject it (in case something went wrong or you didn't like communicating with the user).</p>
                        </div>
                        <div className="mt-5 py-1">
                            <h2 className="h4 mb-4">What the best place to meet? <span role="img" aria-label="coffee">☕</span></h2>
                            <p className="ml-3 text-section-border">I’d say that aany coffee shop is always a great place to meet someone. It would be better to ask your buddy if he/she has an idea. How about Starbucks?</p>
                        </div>
                        <div className="mt-5 py-1">
                            <h2 className="h4 mb-4">Can I share my phone number or social with other users? <span role="img" aria-label="mobile">📱</span></h2>
                            <p className="ml-3 text-section-border">That's <b>not forbidden</b>, but it would be better to use BuddyList private chat as the main platform for communication. Otherwise, use whatever is comfortable for both of you.</p>
                        </div>
                        <div className="mt-5 py-1">
                            <h2 className="h4 mb-4">What are the matching parameters? <span role="img" aria-label="masks">🎭</span></h2>
                            <p className="ml-3 text-section-border">Users will get matched based on all kinds of parameters. Here you can take a look at some of them:
                            <ul>
                                <li><b>Location</b> (both users should be from the same location, for example, the city - but the closer the better). I want users to make in-person connections, so both participants suppose be from the same area.</li>
                                <li><b>Age</b> (users should be around the same age). I believe that similar age will help both users get along and feel comfortable.</li>
                                <li><b>Occupation</b> (users suppose to be from a similar professional field in order to match). BuddyList is a platform for making professional connections first of all.</li>
                                <li><b>Professional & General Interests</b>. Wouldn’t you mind if your potential buddy would have some similar interests like video games, coffee, running, surfing? I personally think that common interests are a really important aspect of making a strong connection with somebody.</li>
                            </ul>
                            That’s not the end of the list, I just wanted to show you the idea under the hood and shed some light on the matching process.
                            </p>
                        </div>
                        <div className="mt-5 py-1">
                            <h2 className="h4 mb-4">Why launch delayed until 5 000 users reached? <span role="img" aria-label="thinking-face">🤔</span></h2>
                            <p className="ml-3 text-section-border">We want to reach certain amount of users before we launch the project, that way we can make sure everybody get the best experience possible. It's hard to raffle users between each other, when there are only few users on the platform. We'd be happy <span role="img" aria-label="kitty">😺</span> if you help us to launch the project faster, please, consider joining and sharing <span role="img" aria-label="praying-hands">🙏</span>.</p>
                        </div>
                        <div className="mt-5 py-1">
                            <h2 className="h4 mb-4">Why do you ask for phone number during registeration? <span role="img" aria-label="bell">🔔</span></h2>
                            <p className="ml-3 text-section-border">Your phone number will <b><u>NOT</u></b> be visible on your profile and will be only used to send you notifications about new buddy matches and messages. I want to make the user experience more smooth, so you will not have to check the BuddyList website all the time just to see if you missed any messages or requests. You will be able to opt-out from SMS notifications at any time.</p>
                        </div>
                    </div>
                    <div className="call-to-action text-center mb-5">
                        <p className="lead desc">Ready to get new connections?</p>
                        <Link to="/register" className="btn btn-success">Join Now</Link>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
            <By />
        </div>
    )
}

export default HowItWorks;