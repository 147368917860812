import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { setupCrop } from '../../actions/formData';
import Pica from 'pica';

const AvatarCrop = ({ avatar: { image, crop, croppedAvatar }, setupCrop }) => {

    const pica = Pica();

    const imageRef = useRef({
        current: null
    });

    const onImageLoaded = (image) => {
        imageRef.current = image
    }
    
    const onCropChange = (crop, percentCrop) => {
        setupCrop({ crop: percentCrop })
    }
    
    const onCropComplete = (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(imageRef.current, crop)
            setupCrop({ croppedImageUrl: croppedImageUrl })
        }
    }

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
         )

        setupCrop({ croppedAvatar: canvas })
    }

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        setupCrop({ croppedAvatar: croppedImage })
    }

    const handleClick = () => {
        // create resized avatar instance
        let canvas = document.createElement("canvas");
        // final size output of the resized avatar canvas
        canvas.width = 400;
        canvas.height = 400;
        // resize the initial canvas into a new canvas called "canavas"
        pica.resize(croppedAvatar, canvas)
        .then(() => {
            const reader = new FileReader()
            canvas.toBlob(blob => {
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    dataURLtoFile(reader.result, 'cropped-and-resized.jpg')
                }
            })
        })

        setupCrop({ avatarPreview: false })
    }

    return (
        <Fragment>
            <div className="text-center">
                <div className="my-5">
                    <h1 className="col-md-12 title text-center h1"><span role="img" aria-label="scissors">✂️</span> Image Cropping</h1>
                    <p className="lead text-center">Please, select an area (e.g face) & crop your image in the section below:</p>
                </div>
                <ReactCrop
                src={image}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                circularCrop={true}
                /> 
                <div className="text-center my-3">
                    {/* { croppedAvatar && <Spinner /> } */}
                    <div className="text-center lead mb-3"><span>Select an area & press the "Crop" button.</span></div>
                    <button type="button" className="btn btn-lg btn-success" onClick={handleClick}>Crop</button>
                </div>
            </div>
        </Fragment>
    )
}

AvatarCrop.propTypes = {
    avatar: PropTypes.object.isRequired,
    setupCrop: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    avatar: state.formData.settings.avatar
})

export default connect(mapStateToProps, { setupCrop })(AvatarCrop);

